* {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  font-size: 14px;
}

table,
.divTable {
  width: fit-content;
}

.tr {
  display: flex;
}

tr,
.tr {
  width: fit-content;
  height: 30px;
  border: 1px solid #dfe1e5;
}

th,
.th {
  padding: 0.25rem;
}

th,
.th {
  padding: 2px 4px;
  position: relative;
  font-weight: bold;
  text-align: center;
  height: 30px;
}

td,
.td {
  height: 30px;
}

.resizer {
  position: absolute;
  top: 0;
  height: 100%;
  width: 5px;
  background: rgba(0, 0, 0, 0.5);
  cursor: col-resize;
  user-select: none;
  touch-action: none;
  opacity: 0;
  transition: opacity 0.2s ease; /* Smooth transition for hover */
}

.resizer.ltr {
  right: 0;
}

.resizer.rtl {
  left: 0;
}

.resizer.isResizing {
  background: white;
  opacity: 1;
}

@media (hover: hover) {
  .resizer {
    opacity: 0;
  }

  *:hover > .resizer {
    opacity: 1;
  }

  .resizer.isResizing:hover ~ .resizer {
    opacity: 0 !important;
  }
}

.custom-collapse {
  transition: height 1s ease;
}

.custom-collapse .MuiCollapse-wrapperInner {
  transition: transform 1s ease, opacity 1s ease;
}
